import { Grid, styled } from '@mui/material';
import useDynamicTheme from 'hooks/useDynamicTheme';
import React from 'react';
import ContentHeader from './ContentHeader';
import SidebarContainer from './SidebarContainer';
import useIsMobile from '/hooks/useIsMobile';

const Root = styled(Grid, {
  shouldForwardProp: (prop) => prop !== 'hasHeader' && prop !== 'greyBackground',
})<{ hasHeader: boolean; greyBackground: boolean }>(({ theme, hasHeader, greyBackground }) => ({
  alignItems: hasHeader ? 'stretch' : 'flex-start',
  maxWidth: '1280px',
  backgroundColor: greyBackground ? '#F5F5F5' : 'white',
  flexDirection: 'column',
  height: hasHeader ? '100%' : undefined,
  [theme.breakpoints.up('xs')]: {
    padding: `0 16px`,
  },
  [theme.breakpoints.up('sm')]: {
    padding: `0 16px`,
  },
  [theme.breakpoints.up('md')]: {
    padding: `${hasHeader ? '0' : '40px'} 24px`,
  },
  [theme.breakpoints.up('lg')]: {
    padding: `${hasHeader ? '0' : '40px'} 24px`,
  },
}));

const MainContent = styled(Grid)(() => ({
  width: '100%',
  minHeight: '100vh',
}));

interface Props {
  children: React.ReactNode;
  greyBackground?: boolean;
  header?: React.ReactNode;
  sidebar?: Record<string, string>;
  sidebarComponent?: React.ReactNode;
}

const Content: React.FC<Props> = ({ children, sidebarComponent, greyBackground, header, sidebar }) => {
  const isMobile = useIsMobile();
  const theme = useDynamicTheme();

  const sidebarExists = sidebar || sidebarComponent;
  const mainContentSize = sidebarExists ? 9 : 12;
  const shouldBeGray = Boolean(greyBackground) && !isMobile;

  return (
    <>
      <Root container hasHeader={!!header} greyBackground={shouldBeGray} justifyContent="center">
        <ContentHeader header={header} />
        <Grid container spacing={4}>
          <SidebarContainer sidebar={sidebar} sidebarComponent={sidebarComponent} />
          <MainContent item xs={12} md={mainContentSize}>
            {children}
          </MainContent>
        </Grid>
      </Root>
    </>
  );
};

export default Content;
