import { ThemeColors } from 'types/types';
import * as Backend from '/queries/BackendRequest';

const fetchTheme = async (pid: string): Promise<ThemeColors> => {
  const result = await Backend.request<ThemeColors>('get', `/content-public/${pid}/theme/colors`);
  return result.data.data;
};

const getTheme = (pid: string) => ({
  queryKey: ['getTheme', pid],
  queryFn: () => fetchTheme(pid),
  enabled: !!pid,
  gcTime: 1000 * 60 * 60, // 1 hour
  retry: 1,
  retryDelay: 20000,
});

export default getTheme;
