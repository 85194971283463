import { PublicProjectTexts } from 'types/types';
import * as Backend from '/queries/BackendRequest';

const fetchPublicText = async (pid: string): Promise<PublicProjectTexts> => {
  const result = await Backend.request('GET', `/content-public/${pid}/strings`);
  return result.data?.data;
};

const getPublicText = (pid: string) => ({
  queryKey: ['getPublicText', pid],
  queryFn: () => fetchPublicText(pid),
  enabled: !!pid,
  gcTime: 1000 * 60 * 60 * 24, // 1 day
  retry: 3,
  retryDelay: 500,
});

export default getPublicText;
